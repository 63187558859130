import { timeHeader } from '@/helpers/ag-grid/headerFormats'

export default [
    {
        headerName: 'ID',
        field: 'id',
    },
    {
        headerName: 'LEA',
        field: 'lea',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    {
        headerName: timeHeader('Update Date'),
        field: 'updateDate',
        type: 'date',
    },
    {
        headerName: 'Is Deleted',
        field: 'isDeleted',
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Student ID',
        field: 'studentId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Discipline ID',
        field: 'uniqueDisciplineId',
        filter: false,
    },
    {
        headerName: 'Incident Date',
        field: 'incidentDate',
        filter: false,
    },
    {
        headerName: 'Incident Code',
        field: 'incidentCode',
        filter: false,
    },
    {
        headerName: 'Incident Name',
        field: 'incidentName',
        filter: false,
    },
    {
        headerName: 'Incident Category',
        field: 'incidentCategory',
        filter: false,
    },
    {
        headerName: 'Incident Location',
        field: 'incidentLocation',
        filter: false,
    },
    {
        headerName: 'Incident Location Detail',
        field: 'incidentLocationDetail',
        filter: false,
    },
    {
        headerName: 'Action Taken Code',
        field: 'actionTakenCode',
        filter: false,
    },
    {
        headerName: 'Action Taken Name',
        field: 'actionTakenName',
        filter: false,
    },
    {
        headerName: 'Action Taken Days',
        field: 'actionTakenDays',
        filter: false,
    },
    {
        headerName: 'Action Taken Start Date',
        field: 'actionTakenStartDate',
        filter: false,
    },
    {
        headerName: 'Action Taken End Date',
        field: 'actionTakenEndDate',
        filter: false,
    },
    {
        headerName: 'Incident Time',
        field: 'incidentTime',
        filter: false,
    },
    {
        headerName: 'Incident Behavior Type Name',
        field: 'incidentBehaviorTypeName',
        filter: false,
    },
    {
        headerName: 'Parent Contacted',
        field: 'parentContacted',
        filter: false,
    },
    {
        headerName: 'Parent Contacted Date',
        field: 'parentContactedDate',
        filter: false,
    },
    {
        headerName: 'Entered By User',
        field: 'enteredByUser',
        filter: false,
    },
    {
        headerName: 'Observed By User',
        field: 'observedByUser',
        filter: false,
    },
    {
        headerName: 'Incident Motivation Name',
        field: 'incidentMotivationName',
        filter: false,
    },
    {
        headerName: 'Discipline Detail',
        field: 'disciplineDetail',
        filter: false,
    },
    {
        headerName: 'Entry Time',
        field: 'entryTime',
        filter: false,
    },
]
